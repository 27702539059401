/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTab {
    &-Button {
        padding-block: 0;
        padding-inline: 0;
        color: var(--primary-base-color);
        text-transform: uppercase;

        @include desktop {
            padding-block: 0 8px;
        }

        @include tablet {
            padding-block: 0 8px;
        }

        &:hover {
            color: $black;
        }
    }

    &-Item {
        list-style-type: none;
    }
}
