/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccount {
    background-color: var(--light-beige);
    margin: 0;
    padding-block-start: calc(35px + var(--current-header-height));
    padding-block-end: 72px;
    height: unset;
    min-height: unset;

    @include after-mobile {
        padding-block-end: 120px;
    }

    &-DiscountSection {
        @include mobile {
            margin-block-start: 60px;
        }
    }

    &-TabContent {
        background-color: var(--color-beige);
        width: 100%;
        max-width: 980px;

        @include desktop {
            overflow-x: unset;
            padding: 23px;
        }

        &_activeTab {
            &_my-orders {
                .MyAccount {
                    &-HeadingWrapper {
                        display: inline-flex;
                        align-items: center;
                        margin-block-end: 12px;
                    }

                    &-Heading {
                        display: flex;
                        margin-inline-end: 16px;
                        margin-block-end: 24px;

                        @include mobile {
                            margin-inline-end: 0px;
                            margin-block-end: 0px;
                        }

                        @include medium-tablet {
                            margin-inline-end: 0px;
                            margin-block-end: 0px;
                        }
                    }

                    &-SubHeading {
                        padding: 2px 8px;
                        font-size: 1rem;
                        line-height: 1.25rem;
                        font-weight: 400;
                        font-style: normal;
                        text-transform: none;
                        margin-inline-start: 0;
                        margin-block: 12px 0;
                        font-family: var(--current-font-family);
                        border-width: 1px;

                        &_is {
                            &_New {
                                color: $black;
                                border-color: $black;
                            }

                            &_Pending.payment {
                                color: $black;
                                border-color: $black;
                            }

                            &_Processing {
                                color: var(--gold-color);
                                border-color: var(--gold-color);
                            }

                            &_In.transit {
                                color: var(--gold-color);
                                border-color: var(--gold-color);
                            }

                            &_Holded {
                                color: var(--new-bordo-color);
                                border-color: var(--new-bordo-color);
                            }

                            &_Closed {
                                color: var(--new-bordo-color);
                                border-color: var(--new-bordo-color);
                            }

                            &_Cancelled {
                                color: var(--new-bordo-color);
                                border-color: var(--new-bordo-color);
                            }

                            &_Complete {
                                color: var(--primary-base-color);
                                border-color: var(--primary-base-color);
                            }

                            &_Packaging {
                                color: var(--gold-color);
                                border-color: var(--gold-color);
                            }
                        }

                        @include desktop {
                            display: flex;
                            width: fit-content;
                            height: fit-content;
                        }
                    }
                }
            }

            &_newsletter-subscription {
                .MyAccount-Heading {
                    margin-block-end: 0;
                }
            }

            &_editing {
                overflow-x: unset;

                .MyAccount {
                    &-Heading {
                        font-size: 0.875rem;
                        text-align: center;
                        line-height: 1.0625rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-block-end: 16px;
                    }
                }
            }

            &_address {
                .MyAccount {
                    &-Heading {
                        margin: 0;
                        margin-block-end: 24px;
                    }
                }
            }

            &_my-wishlist {
                .MyAccount {
                    &-Heading {
                        @include after-mobile {
                            display: none;
                            margin: 0px;
                        }

                        @include medium-tablet-only {
                            display: block;
                            margin-block-end: 24px;
                        }
                    }
                }
            }
        }
    }

    &TabList {
        @include desktop {
            flex: 1;
        }
    }

    &-Welcome {
        font-family: $font-Prospectus-Pro-Cyrillic-Italic;
        font-weight: 400;
        font-size: 42px;
        line-height: 100%;
        margin-block-end: 24px;
    }

    &CustomerTable {
        @include mobile {
            display: flex;
            flex-direction: column;
        }

        &-FullName {
            text-transform: capitalize;
            gap: 6px;
            display: flex;
        }

        &-AccountDetails {
            gap: 8px;
            display: flex;
            flex-direction: column;

            @include mobile {
                flex: 1;
            }
        }

        &-Buttons {
            @include mobile {
                display: flex;
                margin-block-start: 24px;
                flex-wrap: nowrap;
            }
        }
    }

    &-Overlay {
        position: absolute;
        opacity: 1;
        background: var(--light-beige);
        inset-block-start: 30%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        padding-inline: 16px;
        padding-block: 52px;

        @include desktop {
            padding-inline: 32px;
        }

        &Wrapper {
            position: fixed;
            background: transparent;
            width: 100%;
            height: 100%;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: 200;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: var(--color-black);
                opacity: 0.2;
                z-index: -1;
            }
        }

        .MyAccount {
            &-TabContent {
                width: 296px;
                padding: unset;
                background: inherit;
                border: none;

                @include desktop {
                    width: 420px;
                }

                &CloseIcon {
                    position: absolute;
                    cursor: pointer;
                    inset-block-start: 17px;
                    inset-inline-end: 20px;
                    z-index: 1;

                    .CloseIcon {
                        fill: var(--primary-base-color);
                        width: 22px;
                        height: 17px;
                    }
                }

                &-Heading {
                    font-family: unset;
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-align: center;
                    line-height: 1.125rem;
                    margin-block-end: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }

    &-Heading {
        margin-block-end: 24px;
        align-items: center;
        gap: 16px;

        @include mobile {
            display: block;
        }
    }

    &-Wrapper {
        @include desktop {
            display: flex;
            gap: 0;
            padding-inline: 60px;
            padding-block: 0;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline: 16px;
        }
    }

    .Breadcrumbs {
        padding: 0;
        border-block-end: 1px solid rgba(var(--black-rgb-color), 0.15);
        margin-block: 24px 32px;

        @include desktop {
            display: none;
        }

        .ContentWrapper {
            padding-inline: 0;
        }

        .ChevronIcon {
            transform: rotate(0deg);
        }

        .Breadcrumb {
            padding-block-end: 8px;
            text-transform: uppercase;
        }
    }
}
