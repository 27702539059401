/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabListItem {
    list-style-type: none;

    &-Button {
        font-size: 0.875rem;
        font-weight: 600;
        color: $default-primary-base-color;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        width: 100%;
        min-width: 324px;

        .ChevronIcon {
            width: 14px;
            height: 14px;
        }

        @include mobile {
            min-height: 24px;
            min-width: unset;
            padding: 0;
        }
    }

    &_isActive {
        .ChevronIcon {
            color: $default-primary-dark-color;
        }

        button {
            color: $default-primary-dark-color;
        }

        @include desktop {
            margin-inline-start: 16px;
        }

        @include mobile {
            display: block;

            .ChevronIcon {
                color: $default-primary-base-color;
            }

            button {
                color: $default-primary-base-color;
            }
        }
    }

    @include mobile {
        margin-block-end: 16px;

        &:last-child {
            margin-block-end: 0;
        }
    }
}
