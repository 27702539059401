/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderInformation {
    &-ExpandableContent {
        &:last-of-type {
            border-block-end: none;
        }
    }

    &-Information {
        .ExpandableContent {
            &::before {
                height: 1px;
                inset-block-end: -1px;
            }
        }
    }
}

.KeyValueTable {
    overflow: hidden;
}
