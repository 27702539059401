/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTabs {
    &::after {
        display: none;
    }

    @include medium-tablet {
        flex-direction: row;
    }

    @include mobile {
        flex-direction: row;
        align-items: flex-end;
        border-bottom: none;
    }

    .ProgressBar {
        &-Actions {
            @include mobile {
                width: 100%;
            }
        }

        &-ArrowsWrapper {
            @include mobile {
                display: none;
            }
        }
    }
}
