/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderItemsTable {
    &-Headings {
        @include medium-tablet {
            display: table-row;
        }

        th {
            @include medium-tablet-only {
                width: 100%;
            }
        }
    }

    &-Products {
        margin-block-end: 0;

        tbody {
            border: none;
            margin-block-end: 16px;

            @include after-mobile {
                border-block-end: 1px solid rgba(var(--rgb-black-color), 0.16);
            }

            @include medium-tablet {
                display: table-row-group;
            }

            tr {
                @include mobile {
                    display: flex;
                    justify-content: center;
                    border: 1px solid rgba(var(--rgb-black-color), 0.15);
                }

                @include medium-tablet {
                    display: table-row;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }

                th {
                    @include mobile {
                        background-color: var(--secondary-gray-color);
                        padding-block: 12px;
                        display: flex;
                        align-items: center;
                        padding-inline: 16px 0;
                        width: 50%;
                        text-transform: uppercase;
                        border-inline-end: 1px solid rgba(var(--rgb-black-color), 0.15);
                        letter-spacing: 0.07rem;
                    }
                }

                td {
                    @include mobile {
                        padding-block: 12px;
                        padding-inline: 16px;
                        width: 50%;
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        line-height: 126%;
                    }

                    @include medium-tablet {
                        display: table-cell;
                    }

                    ul {
                        li:last-child {
                            @include mobile {
                                margin-block-end: 0;
                            }
                        }
                    }
                }
            }

            ul {
                li {
                    list-style-type: none;
                }
            }
        }

        thead {
            tr {
                th {
                    background-color: var(--secondary-gray-color);
                    text-transform: uppercase;
                    border-radius: 0;
                    padding-inline: 16px 0;
                    padding-block: 12px;

                    &:nth-child(n+3) {
                        text-align: start;
                    }
                }
            }
        }
    }

    &-OrderTitle {
        display: none;
    }

    &-ProductsWrapper {
        margin-block-start: 32px;
    }
}
