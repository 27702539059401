/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTableRow {
    &-Status {
        @include small-tablet {
            padding: 1px 7px;
        }

        &_is {
            &_New {
                color: $black;
                border-color: $black;
            }

            &_Pending.payment {
                color: $black;
                border-color: $black;
            }

            &_Processing {
                color: var(--gold-color);
                border-color: var(--gold-color);
            }

            &_In.transit {
                color: var(--gold-color);
                border-color: var(--gold-color);
            }

            &_Holded {
                color: var(--new-bordo-color);
                border-color: var(--new-bordo-color);
            }

            &_Closed {
                color: var(--new-bordo-color);
                border-color: var(--new-bordo-color);
            }

            &_Cancelled {
                color: var(--new-bordo-color);
                border-color: var(--new-bordo-color);
            }

            &_Complete {
                color: $default-primary-base-color;
                border-color: $default-primary-base-color;
            }

            &_Packaging {
                color: var(--gold-color);
                border-color: var(--gold-color);
            }

            &_Canceled.unpaid {
                color: var(--new-bordo-color);
                border-color: var(--new-bordo-color);
            }
        }
    }

    &-QtyList {
        li {
            list-style-type: none;
        }
    }

    .hidden-mobile {
        @include small-tablet {
            display: table-cell;
        }
    }
}

.MyAccountMyOrders {
    &-NoOrders {
        height: 50vh;
    }
}
