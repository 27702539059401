/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCustomerTable {
    &-AccountDetails {
        font-size: 1rem;
    }

    &-Buttons {
        .Button {
            &_isHollow {
                letter-spacing: 0.07rem;

                &:not([disabled]) {
                    &:hover {
                        @include desktop {
                            padding-inline: 24px 24px;
                        }
                    }
                }
            }
        }
    }

    th {
        display: none;
    }
}
