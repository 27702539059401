/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOverlay {
    &-Legend {
        .Checkbox {
            padding-block-start: 9px;
        }
    }

    &-PasswordBlock + .Field-Wrapper_type_checkbox {
        padding-block-start: 9px;
    }

    &-Action {
        &_accountMenuState_createAccount {
            .Checkbox {
                .Field {
                    &_hasError {
                        color: unset;

                        .CheckBoxIconControl {
                            border-color: var(--primary-error-color);
                        }
                    }
                }

                .Field-InputWrapper {
                    align-items: flex-start;
                    display: flex;
                }
            }
        }
    }

    &-SignUpButton {
        @include mobile {
            margin-block-end: 0;
        }
    }
}
