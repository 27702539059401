/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountMyOrders {
    &-Table {
        td,
        th {
            @include medium-tablet {
                border: none;
                border-bottom: 1px solid rgba(var(--black-rgb-color), 0.15);
            }
        }

        th {
            @include mobile {
                letter-spacing: 0.07rem;
            }
        }

        td {
            line-height: 1.25rem;

            &:nth-child(3) {
                padding: 10px 16px;
            }

            @include mobile {
                font-size: 1rem;
                line-height: 126%;
            }
        }
    }

    &-NoOrders {
        @include after-mobile {
            margin-block-start: 16px;
        }
    }

    .hidden-mobile {
        @include medium-tablet {
            display: table-cell;
        }
    }
}
