/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrder {
    &-Reorder {
        &::before {
            content: "";
            position: absolute;
            inset-block-end: 0;
            inset-inline-end: 0;
            width: 0;
            height: 2px;
            background-color: $black;
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            border: none;
            padding-block-end: 0;

            &::before {
                inset-inline-start: 0;
                inset-inline-end: auto;
                width: 100%;
            }
        }
    }

    &-SubscribeToStatus {
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            inset-block-end: 0;
            inset-inline-end: 0;
            width: 0;
            height: 2px;
            background-color: $black;
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover {
            border: none;
            padding-block-end: 0;

            &::before {
                inset-inline-start: 0;
                inset-inline-end: auto;
                width: 100%;
            }
        }
    }

    &-PrintOrder {
        /** Copied the style from the elements Reorder and SubscribeToStatus
        */
        text-decoration: none;
        font-size: 1rem;
        width: max-content;
        margin-inline-start: 0;
        padding-block-end: 32px;

        @include mobile {
            display: inline-block;
        }

        &::before {
            content: "";
            position: absolute;
            inset-block-end: 0;
            inset-inline-end: 0;
            width: 0;
            height: 2px;
            background-color: $black;
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
            margin-block-end: 32px;
        }

        &:hover {
            border: none;

            &::before {
                inset-inline-start: 0;
                inset-inline-end: auto;
                width: 100%;
            }
        }
    }

    &-Buttons {
        @include medium-tablet {
            display: none;
        }
    }
}
