/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabList {
    background-color: var(--light-beige);

    @include medium-tablet {
        padding: 24px 18px;
        border: 1px solid var(--expandable-content-divider-color);
    }

    &-ExpandableContentContent {
        max-height: unset;
        opacity: 1;

        @include mobile {
            width: 100%;
        }
    }

    &-Separator {
        display: none;
    }

    &-ExpandableContentButton {
        display: none;
    }

    @include desktop {
        border-right: none;
    }

    @include mobile {
        min-width: 292px;
        border: 1px solid var(--expandable-content-divider-color);
        padding: 24px 18px;
    }
}
