/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressPopup {
    line-height: unset;

    .Button {
        height: 2.875rem;
        width: fit-content;

        &:hover {
            height: 2.875rem;
            width: fit-content;
        }

        &:not([disabled]):hover {
            height: 2.875rem;
            width: fit-content;
        }

        &-Cancel {
            padding-inline: var(--button-padding);
        }
    }

    .FieldForm {
        &-Fields {
            display: grid;
            gap: 8px;
        }
    }

    .Field {
        margin-block-start: 0px;
    }

    .FieldGroup {
        display: grid;
        gap: 7px;
    }

    &-Address {
        .KeyValueTable {
            tbody {
                th {
                    display: block;
                    width: 0px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .Popup {
        &-Content {
            .Form {
                .FieldForm {
                    &-Fields {
                        .Field {
                            &-SubLabel {
                                font-size: 0.875rem;
                                line-height: 1.125rem;
                            }

                            &-InputWrapper {
                                input {
                                    height: auto;
                                }

                                .FieldSelect-Clickable {
                                    select {
                                        height: auto;
                                        padding-block: 0.875rem;
                                    }
                                }
                            }

                            &_type_checkbox {
                                .Field-InputWrapper {
                                    input {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    &-Body {
                        .MyAccount {
                            &-Button {
                                transition: background .3s ease-in-out, border .3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }

    .FieldSelect {
        &-Select {
            font-size: 1rem;
        }
    }

    .Loader {
        background-color: transparent;
    }
}
