/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LoginAccount {
    font-size: 1rem;
    padding-block-start: calc(var(--header-padding-height) - 25px);

    &_isMobile {
        .ContentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-block-end: 20px;
        }

        .MenuOverlay-Close {
            inset-inline-end: 20px;
            inset-block-start: 24px;
        }
    }

    input {
        padding-inline: 15px;
        background-color: var(--light-beige);
        box-shadow: 0 1px 0 rgba(var(--input-box-shadow-color), 0.25);
        width: 100%;
        border-block-end: none;
    }

    .Form {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0;
    }

    .MyAccountOverlay {
        &-AdditionalFieldsFormHeader {
            padding-block-end: 12px;
        }
    }

    .Field {
        margin-block: 0;

        &_type_date {
            input {
                padding-block: 20px 7px;
                color: rgba(var(--input-box-shadow-color), 0.5);

                &::-webkit-calendar-picker-indicator {
                    background: none;
                }
            }
        }

        &-Wrapper {
            &_type {
                &_checkbox {
                    padding-block-start: 19px;
                }
            }
        }
    }

    .Field-Wrapper_type_text + .Field-Wrapper_type_checkbox {
        padding-block-start: 8px;
    }
}
