/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTotals {
    &-Wrapper {
        .hidden-mobile {
            @include medium-tablet {
                display: none;
            }
        }

        th {
            &:nth-child(2) {
                @include mobile {
                    width: 164px;
                    min-width: 164px;
                }

                @include medium-tablet-only {
                    width: 164px;
                    min-width: 164px;
                }
            }
        }

        tr {
            @include mobile {
                display: flex;
                justify-content: flex-start;
                gap: 16px;
                overflow-wrap: normal;
                padding-inline-start: 16px;

                td {
                    padding-inline-start: 0px;
                }
            }

            @include medium-tablet-only {
                display: flex;
                justify-content: flex-start;
                gap: 16px;
                overflow-wrap: normal;
                padding-inline-start: 16px;

                td {
                    padding-inline-start: 0px;
                }
            }

            &:first-child {
                th, td {
                    padding-block-start: 16px;

                    @media screen and (max-width: 743px) {
                        padding-block-start: 0px;
                    }
                }
            }
        }
    }
}
