/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccount {
    &-Wrapper {
        @media (min-width: 1560px) {
            padding-inline: 0;
        }

        @include desktop {
            grid-column-gap: 0;
        }

        @include medium-tablet {
            display: block;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline: 16px;
            margin-block-start: 0;
        }
    }

    &-Welcome {
        font-family: $font-Prospectus-Pro-Cyrillic-Italic;
        font-weight: 400;
        font-size: 2rem;
        line-height: 100%;
        margin-block-end: 24px;
    }

    &-Heading {
        @include mobile {
            display: block;
            font-size: 2rem;
            margin-block-end: 24px;
        }
    }

    &-TabContent {
        &_activeTab {
            &_my-wishlist,
            &_address,
            &_newsletter-subscription {
                .MyAccount-Heading {
                    @include mobile {
                        margin-block-end: 24px;
                    }
                }
            }
        }

        @include mobile {
            padding-inline: 0;
        }
    }

    &-Breadcrumbs {
        display: flex;
        gap: 12px;
        align-items: center;
        padding-block-end: 8px;
        width: fit-content;

        li {
            margin-block-end: 0;
        }

        .ChevronIcon {
            width: 16px;
            height: 16px;
            fill: $default-primary-base-color;

            &:last-of-type {
                fill: $black;
                opacity: 0.5;
            }
        }

        &Wrapper {
            display: flex;
            width: 100%;
            overflow-x: scroll;
            margin-block-end: 32px;
            border-bottom: 1px solid var(--expandable-content-divider-color);
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
                // stylelint-disable-next-line
                width: 0 !important
            }

            @include medium-tablet {
                margin-block-start: 24px;
            }
        }
    }

    &-Breadcrumb {
        color: $default-primary-base-color;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        white-space: nowrap;
        font-family: $font-Klavika;

        &::before {
            display: none;
        }

        &_isSelected {
            color: $black;
            opacity: 0.5;
        }
    }
}
