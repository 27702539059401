/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOverlay {
    &-MyAccountSection {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        &_isLoggedIn {
            display: unset;
        }
    }

    &-SubDiscountWrapper {
        width: 100%;
        margin-block-start: 0;

        p {
            margin-block-start: 2px;
            margin-block-end: 0;
            font-size: clamp(0.5rem, 0.875rem, 14px);
            line-height: 0.75rem;
        }
    }

    &-Welcome {
        font-family: $font-Prospectus-Pro-Cyrillic-Italic;
        font-weight: 400;
        font-size: 42px;
        line-height: 100%;
    }

    &-Title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $black;
        opacity: 0.5;
        margin-block-start: 24px;
        margin-block-end: 8px;
    }

    &-Divider {
        background-color: $black;
        opacity: 0.15;
        width: 100%;
        height: 1px;
        margin-block-start: 8px;
        margin-block-end: 32px;
    }

    &-Links {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    &-Link {
        width: fit-content;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 126%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        text-decoration: none;
        color: $default-primary-base-color;
        transition: all .3s ease;
        cursor: pointer;

        &:visited {
            color: $default-primary-base-color;
        }

        &:hover {
            padding-inline-start: 16px;
            color: $black;
        }
    }

    &-SignInButton {
        button {
            border-radius: 0;
        }
    }

    &-SignUpButton {
        margin-block-start: 0px;

        @include mobile {
            margin-block-end: 0;
        }
    }

    &-SignInTitle {
        margin-inline-end: 36px;

        &_isActive {
            color: $black;
        }
    }

    &-CreateAccountTitle {
        &_isActive {
            color: $black;
        }
    }

    &-Action {
        max-height: unset;

        @include mobile {
            padding-block-end: 24px;
        }

        .Form {
            gap: 11px;
        }

        .Field {
            &-LabelContainer {
                display: none;
            }
        }

        .SocialLoginButton {
            margin-block-start: 0;

            &:not([disabled]) {
                &:hover {
                    @include desktop {
                        background-color: var(--primary-base-color);
                        border-color: var(--primary-base-color);
                    }
                }
            }
        }

        h4 {
            font-size: 1rem;
            font-weight: normal;
        }

        &_accountMenuState {
            &_forgotPassword {
                .Form {
                    gap: 0;
                }
            }

            &_signIn {
                .Form .Button_likeLink {
                    text-transform: unset;
                    font-weight: 400;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $black;

                        &::after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    &-MenuOverlay {
        input {
            border-bottom: 1px solid transparent;
        }

        .MenuOverlay {
            &-Container {
                padding: 52px 32px;

                @include mobile {
                    padding: 16px;
                    padding-block-end: 24px;
                }

                @include tablet {
                    padding: 16px 42px ;
                    padding-block-end: 24px;
                }

                @include desktop {
                    padding: 24px 60px;
                }
            }

            &-Close {
                display: block;
                transform: none;
                position: fixed;

                @include mobile {
                    inset-block-start: 16px;
                    inset-inline-end: 16px;
                }

                @include tablet {
                    inset-block-start: 16px;
                    inset-inline-end: 42px;
                }

                @include desktop {
                    inset-block-start: 24px;
                    inset-inline-end: 24px;
                }
            }
        }

        .Field_type_date {
            input {
                padding-block-start: 1.625rem;
                padding-block-end: 0.375rem;
                color: $black;
            }
        }

        &_isAdditionalFieldsForm {
            input {
                background-color: var(--popup-input-color);
            }

            &.MenuOverlay_isVisible {
                .MenuOverlay {
                    &-Container {
                        transform: translate(-50%, -50%);

                        @include mobile {
                            width: 90%;
                        }
                    }
                }
            }

            .MenuOverlay {
                &-Container {
                    background-color: var(--light-beige);
                    height: unset;
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    transform: translate(200%,-50%);
                }

                &-Close {
                    display: none;
                }
            }

            .MyAccountOverlay {
                &-Heading {
                    display: none;
                }

                &-MyAccountSection {
                    height: unset;
                }

                &-DiscountSection {
                    display: none;
                }
            }
        }
    }

    .MenuOverlay &-ForgotPassword {
        letter-spacing: normal;
    }

    &-ForgotPasswordHeading {
        margin-block-end: 16px;
    }

    &-ResetPassword {
        margin-block-start: 0px;
    }

    &-SuccessMessage {
        .CheckmarkIcon {
            height: 1rem;
        }
    }

    &-Buttons {
        padding-block-start: 13px;

        .Button {
            border-radius: 0;
        }
    }

    &-GotIt {
        margin-block-end: 8px;
        border-radius: 0;
    }

    &-AdditionalFieldsFormHeader {
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1.1rem;
        padding-block-end: 12px;
    }

    &-DiscountSection {
        position: relative;
        height: 70px;
        min-height: 70px;
        margin-block-start: 16px;

        &_isCreateAccount {
            margin-block-start: 0;
            margin-block-end: 16px;
        }

        .MyAccountOverlay {
            &-SubDiscountWrapper {
                max-width: none;

                @include after-mobile {
                    height: auto;
                    width: 100%;
                }

                &_isMobile {
                    width: 100%;
                }
            }
        }
    }

    &-DiscountWrapper {
        display: unset;
        flex-direction: unset;
        animation: unset;
        inset-block-start: 0;

        .Slider {
            &-Crumbs {
                width: 50px;
                display: flex;
                gap: 6px;
                inset-inline-end: 0;
                inset-block-start: 12px;
                justify-content: flex-end;
            }

            &-Crumb {
                width: 4px;
                height: 4px;
                opacity: 0.5;

                &_isActive {
                    opacity: 1;
                    width: 6px;
                    height: 6px;
                }

                &:hover {
                    @include desktop {
                        cursor: pointer;
                    }
                }
            }

            &-Wrapper {
                align-items: center;
            }
        }
    }

    &-PercentText {
        margin-inline-end: 30px;

        @include desktop {
            margin-inline-end: 40px;
        }
    }

    &-SocialRegSuccess {
        text-align: center;
        padding-block-end: 28px;

        &Image {
            width: 35px;
            height: 35px;
            margin-block-start: 29px;
        }

        &ThankYou {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.26rem;
            padding-block-start: 3px;
        }

        &Message {
            line-height: 1.26rem;
            padding-block-start: 16px;
        }
    }
}

// to increase specificity and give this rule more weight (otherwise it's overwritten by other h4 rules):

h4.MyAccountOverlay {
    &-AdditionalFieldsFormHeader {
        font-size: 0.875rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.1rem;
        padding-block-start: 0px;
        padding-block-end: 12px;
        letter-spacing: 0.08em;
    }
}
