/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountMyWishlist {
    &-Products {
        align-items: stretch;
        margin-block-end: 3%;

        @include narrow-desktop-plus {
            grid-template-columns: repeat(3, 1fr);
        }

        @include medium-tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        .ProductCard {
            &-Picture {
                padding-block-end: 124.4%;

                @include after-mobile {
                    padding-block-end: 131.6%;
                }
            }
        }
    }

    &-Button {
        height: 2.53rem;
        margin-inline-end: 1.34rem;
        letter-spacing: 0.07rem;

        @include mobile {
            width: auto;
            height: 2.375rem;
            flex-basis: 65%;
        }

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            height: 2.53rem !important;
        }
    }

    &-ActionsWrapper {
        gap: 24px;

        @include medium-tablet-only {
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }

        &_isMobile {
            align-items: center;
            justify-content: space-between;
        }
    }

    &-ClearWishlistButton {
        letter-spacing: 0.08em;

        @include mobile {
            white-space: nowrap;
            height: 38px;
        }
    }

    &-ShareWishlistButton {
        margin-inline-end: 0;
    }

    &-Header {
        margin-block-end: 24px;
    }

    &-NoProducts {
        display: flex;
        flex-direction: column;
        height: 50vh;

        p {
            margin-block-end: 24px;
        }
    }

    &-LinkButton {
        font-size: 0.875rem;
        width: 12.25rem;
        height: var(--button-height);
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.07em;
        text-decoration: none;
        border: none;
    }

    :not(a:visited) a:visited {
        color: var(--color-white);
    }

    &-ActionsWrapperButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0;

        @include mobile {
            flex-basis: auto;
        }

        @include small-tablet {
            flex-basis: auto;
        }
    }

    &-Heading {
        @include medium-tablet-only {
            display: none;
        }
    }
}
